import {Ref, computed, ref} from 'vue';
import {
  collectBySection,
  ContentEntrySection
} from '../../../../../backend/content/content-entry/content-entry-utils';
import {ProgressMap} from '../../../../../backend/progress/progress-types';
import {useLocalStorageFlag} from '../../../../vue-composition/local-storage-flag/local-storage-flag';
import {useSearch} from '../../../../vue-composition/search/search';
import {useSidebar} from '../../../../vue-composition/sidebar/sidebar';
import {ContentEntrySortScheme} from '../../../../vue-composition/sort/types';
import {CollectionComposition} from './types';
import FilterMenu from '../../../../generic/filter-menu/FilterMenu.vue';
import {getCollectionFilterSet} from './implementation/collection-filter-set';
import {AccessPassStatus} from '../../../../../backend/access-pass/access-pass-types';
import {getCollectionSort} from './implementation/collection-sort';

export function useCollection(
  sections: Ref<ReadonlyArray<ContentEntrySection> | undefined>,
  progress: Ref<Readonly<ProgressMap> | undefined> | undefined,
  accessPasses: Ref<ReadonlyArray<AccessPassStatus> | undefined>,
  sortSchemes: ReadonlyArray<ContentEntrySortScheme>,
  localStorageKey?: string
): Readonly<CollectionComposition> {
  //
  // Remember whether the index is shown as a grid or as a list.
  //
  const localStorage = useLocalStorageFlag('content-entry-index-grid', true);
  const layout = computed(() => {
    const grid = localStorage.get();
    return grid ? 'cards' : 'list';
  });

  //
  // Filter state
  //
  const filterSet = getCollectionFilterSet(
    sections,
    progress ? progress : ref(undefined),
    accessPasses,
    localStorageKey
  );
  const filterOutput = computed(() => {
    return filterSet.filterOutput.value;
  });

  //
  // Sort state
  //
  const sort = getCollectionSort(filterOutput, sortSchemes, localStorageKey);

  //
  // Search state
  //
  const search = useSearch(sort.sortOutput);

  //
  // Combined output
  //
  const searchSortFilterOutput = computed(() => {
    return collectBySection(search.searchOutput.value);
  });

  //
  // Filter sidebar
  //
  const sidebar = useSidebar();
  const showFilterSidebar = () => {
    sidebar.show(FilterMenu, {
      filterSet,
      sort: sort,
      search: search,
      onClose: () => {
        sidebar.hide();
      }
    });
  };

  return {
    showFilterSidebar,
    layout,
    filterSet,

    sort,
    search,
    searchSortFilterOutput
  };
}
