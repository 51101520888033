<template>
  <pill v-if="enabled" class="border-dark bg-gradient-to-r from-primary to-orange-300 text-black">
    Coming Soon
  </pill>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {ContentEntry} from '../../../../../backend/content/content-entry/content-entry-types';
import {entryIsPending} from '../../../../../backend/craft/entry/entry-utils';
import Pill from '../../../../generic/pill/Pill.vue';

export default defineComponent({
  components: {
    Pill
  },
  props: {
    contentEntry: {type: Object as PropType<Readonly<ContentEntry>>, required: true}
  },
  setup(props) {
    const enabled = computed(() => {
      return entryIsPending(props.contentEntry);
    });
    return {
      enabled
    };
  }
});
</script>
