import {ImgixImageConfig, ImgixImageWidth} from '../../../components/core/imgix-image/types';
import {CraftSectionHandle} from '../../craft/craft-types';
import {ImageCollection} from '../../image-collection/image-collection-types';
import {findImage} from '../../image-collection/image-collection-utils';
import {fetchContentEntries} from './content-entry-query';
import {contentEntries} from './content-entry-query-builder';
import {ContentEntry, CraftQueryBuilderContentEntries} from './content-entry-types';

/**
 * Fetch content entries in pages until they meet a given condition.
 *
 * NOTE: This will omit entries in the section that the current user does not
 * have access to.
 *
 * @param query Content entry query. (NOTE: its limit and offset values will be ignored.)
 * @param moreEntriesAreNeeded Given the entries fetched so far, return true if more
 * entries should be fetched, false otherwise.
 * @param stopAfter Stop fetching after this number of entries (or more) have been fetched.
 * @param entriesPerPage The number of entries to fetch per page.
 */
export async function fetchContentEntriesUntil<T extends ContentEntry>(
  query: Readonly<CraftQueryBuilderContentEntries>,
  moreEntriesAreNeeded: (entries: ReadonlyArray<T>) => boolean,
  stopAfter: number,
  entriesPerPage: number = 10
): Promise<Array<T>> {
  const limit = entriesPerPage;
  let offset = 0;
  let more = true;
  let result: Array<T> = [];
  do {
    /*
      NOTE: The number of entries returned per fetch (= length of the 'page' array 
      below) may be smaller than `limit`, as the current user may not have access 
      to some (or all) of the entries in that batch.
    */
    const page = await fetchContentEntries<T>(query.limit(limit).offset(offset));
    result = result.concat(page);
    offset += limit;
    more = moreEntriesAreNeeded(result) && result.length < stopAfter;
  } while (more);
  return result;
}

/**
 * Return up to N entries that match the specified filter.
 *
 * @param n The number of entries to return (if possible).
 * @param query The entry query to run.
 * @param filter Only return entries for which this conditional returns true.
 * @param max The maximum number of entries to fetch.
 */
export async function fetchContentEntriesUpToLimit(
  n: number,
  query: Readonly<CraftQueryBuilderContentEntries>,
  filter: (contentEntry: Readonly<ContentEntry>) => boolean = _ => true,
  max: number = 50
): Promise<Array<ContentEntry>> {
  const result = await fetchContentEntriesUntil(
    query,
    (contentEntries: ReadonlyArray<ContentEntry>) => {
      return contentEntries.filter(filter).length < n;
    },
    max,
    n
  );
  const limited = result.slice(0, n);
  return limited;
}

/**
 * Return all pending content entries in the specified section.
 */
export async function fetchPendingContentEntriesInSection(
  sectionHandle: CraftSectionHandle,
  reverse: boolean = false
): Promise<Array<ContentEntry>> {
  try {
    const result = await fetchContentEntries(
      contentEntries().section([sectionHandle]).status(['pending'])
    );
    if (reverse) {
      result.reverse();
    }
    return result;
  } catch (error) {
    throw new Error(
      `Could not fetch pending content entries in section ${sectionHandle}: ${error}`
    );
  }
}

/**
 * Return the tutors for the specified content entry as a human-readable list.
 */
export function tutorList(entry: Readonly<ContentEntry>): string {
  const tutors = entry.tutors.map(tutor => {
    return tutor.title;
  });
  if (tutors.length === 0) {
    return '';
  }
  if (tutors.length === 1) {
    return tutors[0];
  }
  return tutors.slice(0, 1).join(', ') + ' and ' + tutors.slice(-1);
}

/**
 * Return the levels for the specified content entry as a human-readable list.
 */
export function levelList(entry: Readonly<ContentEntry>): string {
  const levels = entry.levels.map(level => {
    return level.title;
  });
  if (levels !== undefined) {
    return levels.join(', ');
  }
  return '(None)';
}

/**
 * Return the subjects for the specified content entry as a human-readable list.
 */
export function subjectList(entry: Readonly<ContentEntry>): string {
  const subjects = entry.subjects.map(subject => {
    return subject.title;
  });
  if (subjects !== undefined) {
    return subjects.join(', ');
  }
  return '(None)';
}

/**
 * Return a config for the first image in the specified collection
 * that matches one of the specified handles. If no matching image
 * was found, return undefined.
 */
// ??? MOVE
export function getImageConfig(
  imageCollection: Readonly<ImageCollection>,
  handles: ReadonlyArray<string>,
  alt: string,
  width: Readonly<ImgixImageWidth>,
  imgixParams?: {}
): Readonly<ImgixImageConfig> | undefined {
  const img = findImage(imageCollection, handles);
  if (img === undefined) {
    return undefined;
  }
  return {
    path: img.imageFilename,
    alt: img.imageAlternateText ? img.imageAlternateText : alt,
    width,
    imgixParams
  };
}

/**
 * Return a variable-size image suitable for presenting the specified Tutor.
 */
// ??? MOVE
export function getAvatarImage(
  imageCollection: Readonly<ImageCollection>,
  altText: string,
  width: Readonly<ImgixImageWidth>
): Readonly<ImgixImageConfig> | undefined {
  const imgixParams = {fit: 'facearea', facePad: 5, aspectRatio: '1:1'};
  return getImageConfig(imageCollection, ['avatar'], altText, width, imgixParams);
}
