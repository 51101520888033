import {CraftSection} from '../../craft/section/section-types';
import {ContentEntry} from './content-entry-types';

export interface ContentEntrySection {
  section: CraftSection;
  contentEntries: Array<ContentEntry>;
}

export function collectBySection(
  contentEntries: ReadonlyArray<ContentEntry>
): ReadonlyArray<ContentEntrySection> {
  const sectionMap: {[handle: string]: ContentEntrySection} = {};
  contentEntries.forEach(ce => {
    if (sectionMap[ce.sectionHandle] === undefined) {
      const section: ContentEntrySection = {
        section: {
          handle: ce.sectionHandle,
          url: '',
          title: ce.sectionName,
          type: ''
        },
        contentEntries: []
      };
      sectionMap[ce.sectionHandle] = section;
    }
    sectionMap[ce.sectionHandle].contentEntries.push(ce);
  });

  const result = Object.values(sectionMap).sort((a, b) =>
    a.section.title.localeCompare(b.section.title)
  );

  return result;
}
