import {fetchContentEntries} from '../../../backend/content/content-entry/content-entry-query';
import {contentEntries} from '../../../backend/content/content-entry/content-entry-query-builder';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {CraftQueryBuilder} from '../../../backend/craft/query/craft-query-builder-types';
import {BreadcrumbTrailComposition, BreadcrumbTrailNode, NavigationPath} from './types';
import {
  breadcrumbTrailToNavigationPath,
  flattenBreacrumbTrail,
  getNextSibling,
  getPreviousSibling
} from './utils';

/**
 * Given a content entry, return the breadcrumb trail that leads to it,
 * as well as its siblings.
 */
export async function useBreadcrumbTrails<TYPE extends ContentEntry = ContentEntry>(
  contentEntry: Readonly<ContentEntry>,
  breadcrumbTrails: string,
  /** Additional content entry fields to fetch for siblings. */
  additionalFields?: ReadonlyArray<CraftQueryBuilder>
): Promise<BreadcrumbTrailComposition<TYPE>> {
  const trailNode = JSON.parse(breadcrumbTrails) as Readonly<BreadcrumbTrailNode>;
  const trail = flattenBreacrumbTrail(trailNode);

  let siblings: ReadonlyArray<TYPE> | undefined = undefined;
  let nextSibling: Readonly<TYPE> | undefined = undefined;
  let previousSibling: Readonly<TYPE> | undefined = undefined;

  // Siblings
  const child = trail[0];
  if (child !== undefined) {
    // ??? We should allow multiple navigation paths to an entry.
    const parent = child.parents[0];

    if (parent !== undefined && parent.id !== undefined) {
      /*
        We want to fetch the parent as a content entry, and include its
        children - with additional fields (for the children) if requested.
        Begin by constructing the query component for the `childEntries`
        field (on the parent)...
      */
      const childEntriesField = contentEntries('childEntries', []);
      if (additionalFields) {
        childEntriesField.fields(additionalFields);
      }

      // ...and then fetch the parent content entry with the child field included.
      const ces = await fetchContentEntries(
        contentEntries().id([parent.id]).fields([childEntriesField])
      );

      // We now have the siblings (the entries in the `childEntries` field of the parent).
      const parentContentEntry = ces[0];
      if (parentContentEntry !== undefined) {
        siblings = parentContentEntry.childEntries as Array<TYPE>;
        if (siblings !== undefined) {
          nextSibling = getNextSibling(siblings, contentEntry.id);
          previousSibling = getPreviousSibling(siblings, contentEntry.id);
        }
      }
    }
  }

  let navigationPath: Readonly<NavigationPath> = [];
  if (trail !== undefined) {
    navigationPath = breadcrumbTrailToNavigationPath(trail);
  }

  return {
    siblings,
    nextSibling,
    previousSibling,
    trail,
    navigationPath
  };
}
