import {computed, Ref, ref} from 'vue';
import {
  ContentEntry,
  CraftQueryBuilderContentEntries
} from '../../../backend/content/content-entry/content-entry-types';
import {
  countContentEntries,
  fetchContentEntries
} from '../../../backend/content/content-entry/content-entry-query';
import {useLoader} from '../loader/loader';
import {collectBySection} from '../../../backend/content/content-entry/content-entry-utils';
import {paginatedAction} from './paginated-action';

export type FetchContentEntriesPaginatedComposition = {
  contentEntries: Ref<Array<ContentEntry>>;
  start: (query: Readonly<CraftQueryBuilderContentEntries>, onFinished?: () => void) => void;
};

/**
 * This Vue composition allows you to fetch a large number of content entries
 * in batches. The fetching happens seamlessly "behind the scenes"; a computed
 * Vue ref is returned that contains an array of the entries fetched thus far.
 * (The ref is updated as new entries arrive.) The entries are guaranteed to
 * be in the same order as they would have been if they had been fetched all
 * at once via fetchContentEntries().
 */
export function usePaginatedContentEntryFetch(
  itemsPerPage: number = 12
): Readonly<FetchContentEntriesPaginatedComposition> {
  const contentEntries = ref<Array<ContentEntry>>([]);
  const loader = useLoader();

  const start = async (
    query: Readonly<CraftQueryBuilderContentEntries>,
    onFinished?: () => void
  ) => {
    loader.setLoading(true);

    const total = await countContentEntries(query);
    let result: Array<ContentEntry> = [];
    paginatedAction(
      total,
      itemsPerPage,
      async (offset, limit) => {
        /*
          NOTE: The number of entries returned by fetchContentEntries() may not match
          the total we got from the content entry count; the reason is that entries not
          accessible by the current user are filtered out from the results. This means
          that we could be fetching some unnecessary batches, but there is no way around
          that; we need to fetch the content entry data in order to know whether the entry
          is accessible.
        */
        const ces = await fetchContentEntries(query.offset(offset).limit(limit));
        result = result.concat(ces);
        contentEntries.value = result as Array<ContentEntry>;
      },
      () => {
        loader.setLoading(false);
        if (onFinished) {
          onFinished();
        }
      }
    );
  };

  return {
    contentEntries,
    start
  };
}

export function usePaginatedContentEntryFetchBySection(itemsPerPage: number = 12) {
  const paginatedFetch = usePaginatedContentEntryFetch(itemsPerPage);
  const sections = computed(() => {
    const result = collectBySection(paginatedFetch.contentEntries.value);
    return result;
  });
  const start = async (
    query: Readonly<CraftQueryBuilderContentEntries>,
    onFinished?: () => void
  ) => {
    paginatedFetch.start(query, onFinished);
  };
  return {
    sections,
    start
  };
}
