import {fetchContentEntries} from '../../../backend/content/content-entry/content-entry-query';
import {contentEntries} from '../../../backend/content/content-entry/content-entry-query-builder';
import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {getAllPlayersPathLevels} from '../../../backend/content/players-path/players-path-query';
import {CraftSlug} from '../../../backend/craft/craft-types';
import {getProgressForEntries} from '../../../backend/progress/progress-query';
import {BreadcrumbTrailComposition} from '../../vue-composition/breadcrumb-trail/types';

const BEGINNER_JOURNEY_LP_ENTRIES = [
  'learning-pathway-beginner-1',
  'learning-pathway-beginner-2',
  'learning-pathway-beginner-3'
];
const BEGINNER_JOURNEY_PP_ENTRIES = [
  'players-path-level-1',
  'players-path-level-2',
  'players-path-level-3'
];

export const BEGINNER_JOURNEY_LEVELS: {[slug: string]: CraftSlug} = {
  'learning-pathway-beginner-1': 'players-path-level-1',
  'learning-pathway-beginner-2': 'players-path-level-2',
  'learning-pathway-beginner-3': 'players-path-level-3'
};

export function entryIsInBeginnerJourney(entry: Readonly<ContentEntry>): boolean {
  return (
    BEGINNER_JOURNEY_LP_ENTRIES.includes(entry.slug) ||
    BEGINNER_JOURNEY_PP_ENTRIES.includes(entry.slug)
  );
}

export async function getBeginnerJourneyLearningPathwaysEntries(): Promise<
  ReadonlyArray<ContentEntry>
> {
  const entries = await fetchContentEntries(
    contentEntries().section(['learningPathways']).slug(BEGINNER_JOURNEY_LP_ENTRIES)
  );
  return entries;
}

export function getPlayersPathLevelForLearningPathway(slug: CraftSlug): CraftSlug {
  return BEGINNER_JOURNEY_LEVELS[slug];
}

export function trailIsInBeginnerJourney(trailComposition: Readonly<BreadcrumbTrailComposition>) {
  const trail = trailComposition.trail;
  if (trail === undefined) {
    return false;
  }
  return (
    trail
      .map(t => t.slug)
      .find(
        slug =>
          slug !== undefined &&
          (BEGINNER_JOURNEY_LP_ENTRIES.includes(slug) || BEGINNER_JOURNEY_PP_ENTRIES.includes(slug))
      ) !== undefined
  );
}

export async function getBeginnerJourneyProgress(guest: string): Promise<number | undefined> {
  if (guest) {
    return undefined;
  }
  const lp = await fetchContentEntries(
    contentEntries().section(['learningPathways']).slug(Object.keys(BEGINNER_JOURNEY_LEVELS))
  );
  const lpProgress = await getProgressForEntries(lp.map(e => e.id));
  const lpTotal = lpProgress.reduce((total: number, lp: number) => {
    return total + lp / 3;
  }, 0);

  const pp = await getAllPlayersPathLevels(guest);
  const ppTotal = pp
    .filter(level => Object.values(BEGINNER_JOURNEY_LEVELS).includes(level.slug))
    .map(level => level.numberOfCompletedPieces / 3)
    .reduce((total: number, lp: number) => {
      return total + lp / 3;
    }, 0);

  return lpTotal / 2 + ppTotal / 2;
}

/**
 * Return a "fake" content entry that pretends to be the Beginner Journey.
 * (The Beginner Journey has no entry in Craft, as it is a combination of three
 * Learning Pathways.)
 */
export function getBeginnerJourneyContentEntry(): Readonly<ContentEntry> {
  return {
    id: '',
    title: 'Beginner Journey',
    url: '/beginner-journey',
    slug: '',
    sectionHandle: '',
    sectionName: '',
    postDate: '',

    childEntries: [],
    summary: null,
    tutors: [
      {
        id: '1588485',
        title: 'Ian Allison',
        slug: 'ian-allison',
        sectionHandle: 'tutors',
        sectionName: 'Tutors',
        url: 'https://scottsbasslessons.local/tutors/ian-allison',
        postDate: '2023-08-10T04:55:00+00:00',
        tutorFocusOfStudy: 'The Sideman Session',
        tutorShortBiography: 'Accomplished professional electric and upright bass player.',
        tutorLongBiography:
          '<p>Ian Allison is an accomplished and versatile electric and upright bass player, working in Minneapolis, MN. Most recently he has spent the last five years touring nationally as part of Eric Hutchinson and The Believers, sharing stages with acts like Kelly Clarkson, Pentatonix, Rachel Platten, Matt Nathanson and Phillip Phillips, and playing venues such as Radio City Music Hall, The Staples Center and The Xcel Center in St. Paul, MN.</p>',
        tutorTwitterHandle: '',
        tutorFeaturedMentor: true,
        imageCollection: [
          {
            imageHandle: 'avatar',
            imageFilename: 'avatars/ian-allison-avatar.jpg',
            imageAlternateText: 'Ian Allison'
          },
          {
            imageHandle: 'profile',
            imageFilename: 'profiles/ian-allison-profile.jpg',
            imageAlternateText: 'Ian Allison'
          },
          {
            imageHandle: 'banner',
            imageFilename: 'mentor-bio-images/ian-allison-banner.jpg',
            imageAlternateText: 'Ian Allison'
          }
        ]
      }
    ],
    subjects: [],
    levels: [],
    campusUrl: null,
    recommendations: [],
    resources: [],
    videos: [],
    imageCollection: [
      {
        imageHandle: 'poster',
        imageFilename: 'content/LP-Thumb-Beg-new.jpg',
        imageAlternateText: 'Beginner Journey'
      }
    ],
    preview: false,
    accessPasses: []
  };
}
