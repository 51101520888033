import {ContentEntry} from '../../../backend/content/content-entry/content-entry-types';
import {CraftId} from '../../../backend/craft/craft-types';
import {BreadcrumbTrail, BreadcrumbTrailNode, NavigationPath} from './types';

export function getNextSibling<TYPE extends ContentEntry>(
  siblings: ReadonlyArray<TYPE>,
  id: CraftId
): Readonly<TYPE> | undefined {
  return siblings.find((_, index, array) => {
    if (array[index - 1] !== undefined) {
      return array[index - 1].id === id;
    }
  });
}

export function getPreviousSibling<TYPE extends ContentEntry>(
  siblings: ReadonlyArray<TYPE>,
  id: CraftId
): Readonly<TYPE> | undefined {
  return siblings.find((_, index, array) => {
    if (array[index + 1] !== undefined) {
      return array[index + 1].id === id;
    }
  });
}

export function breadcrumbTrailToNavigationPath(
  trail: Readonly<BreadcrumbTrail>
): Readonly<NavigationPath> {
  const result = trail
    .map(t => {
      return {title: t.title, url: t.url};
    })
    .reverse();
  result.pop(); // Remove the current entry from the trail.
  return result;
}

export function flattenBreacrumbTrail(
  node: Readonly<BreadcrumbTrailNode>
): Readonly<BreadcrumbTrail> {
  if (node.parents.length === 0) {
    return [node];
  }
  if (node.parents.length > 1) {
    // ??? We should allow multiple navigation paths to an entry.
    throw new Error('Unsupported: Entry cannot have multiple parents');
  }
  return [node].concat(flattenBreacrumbTrail(node.parents[0]));
}
