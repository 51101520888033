<template>
  <column-grid :name="'card'" class="select-none group">
    <content-area :area="cardArea" :class="deviceType.largerThan('sm') ? 'pr-fl-md' : ''">
      <a :href="url">
        <div class="h-full flex flex-col justify-center">
          <card-hero-shimmer v-if="contentEntry === undefined" />
          <card-hero-text
            v-else-if="responsiveImageConfig === undefined"
            :ribbon-icon="ribbonIcon"
            :inset-text="durationText"
          />
          <card-hero-image
            v-else
            :config="responsiveImageConfig"
            :greyscale="greyscale"
            :ribbon-icon="ribbonIcon"
            :inset-text="durationText"
          />
        </div>
      </a>
    </content-area>

    <content-area :area="bodyArea">
      <div v-if="contentEntry !== undefined" class="h-full flex flex-col justify-center group">
        <div class="flex justify-between items-start w-full gap-fl-md">
          <a :href="url" class="grow">
            <flow>
              <card-body-title
                :title="contentEntry.title"
                :font-size="'text-fl-2xl'"
                :text-color="'text-lightest'"
              />
              <cluster :gap="'gap-4'">
                <card-body-completed v-if="progress !== undefined" :progress="progress" />
                <card-body-tutor-list v-if="displayConfig.tutors" :content-entry="contentEntry" />
                <card-body-levels-and-subjects
                  v-if="displayConfig.subjects && displayConfig.levels"
                  :content-entry="contentEntry"
                />
                <card-body-duration v-if="duration !== undefined" :duration="duration" />
                <card-body-post-date v-if="displayPostDate" :content-entry="contentEntry" />
              </cluster>
              <cluster v-if="displayConfig.sectionName || displayConfig.comingSoon" :gap="'gap-4'">
                <card-body-section-name
                  v-if="displayConfig.sectionName"
                  :content-entry="contentEntry"
                />
                <card-body-coming-soon
                  v-if="displayConfig.comingSoon"
                  :content-entry="contentEntry"
                />
              </cluster>
              <card-progress-bar
                v-if="progress !== undefined && !completedStatus && progress > 0"
                :progress="progress"
                :spot-color="spotColor"
              />
              <card-summary v-if="displayConfig.summary === true" :summary="summary" />
              <slot name="body" />
            </flow>
          </a>
          <div>
            <slot name="buttons">
              <card-body-bookmark
                v-if="bookmark !== undefined"
                :guest="guest"
                :content-entry="contentEntry"
                :bookmark="bookmark"
              />
            </slot>
          </div>
        </div>
      </div>
      <variants v-else>
        <template #md+>
          <div class="w-full h-full flex flex-col gap-fl-xs">
            <div :class="`relative w-[80%] h-fl-md bg-darkest`"></div>
            <div :class="`relative w-[60%] h-fl-md bg-darkest`"></div>
          </div>
        </template>
      </variants>
    </content-area>
  </column-grid>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {
  getImageConfig,
  tutorList
} from '../../../../backend/content/content-entry/content-entry-query-utils';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import ColumnGrid from '../../../core/column-layout/ColumnGrid.vue';
import ContentArea from '../../../core/column-layout/ContentArea.vue';
import Variants from '../../../core/column-layout/Variants.vue';
import CardHeroImage from '../../../generic/card/partials/hero/CardHeroImage.vue';
import CardHeroText from '../../../generic/card/partials/hero/CardHeroText.vue';
import CardHeroShimmer from '../../../generic/card/partials/hero/CardHeroShimmer.vue';
import CardProgressBar from '../../../generic/card/partials/CardProgressBar.vue';
import CardBodySectionName from '../../../generic/card/partials/body/CardBodySectionName.vue';
import CardBodyComingSoon from '../../../generic/card/partials/body/CardBodyComingSoon.vue';
import CardBodyTitle from '../../../generic/card/partials/body/CardBodyTitle.vue';
import CardBodyTutorList from '../../../generic/card/partials/body/CardBodyTutorList.vue';
import CardBodyCompleted from '../../../generic/card/partials/body/CardBodyCompleted.vue';
import CardBodyLevelsAndSubjects from '../../../generic/card/partials/body/CardBodyLevelsAndSubjects.vue';
import CardBodyDuration from '../../../generic/card/partials/body/CardBodyDuration.vue';
import CardBodyBookmark from '../../../generic/card/partials/body/CardBodyBookmark.vue';
import CardBodyPostDate from '../../../generic/card/partials/body/CardBodyPostDate.vue';
import CardSummary from '../../../generic/card/partials/CardSummary.vue';
import Flow from '../../../core/compositions/Flow.vue';
import Cluster from '../../../core/compositions/Cluster.vue';
import {useDeviceType, deviceTypeSwitch} from '../../../vue-composition/device-type/device-type';
import {DEFAULT_DISPLAY_CONFIG_INLINE_CARD, DisplayConfig} from '../../collections/types';
import {ColorConfig} from '../../../core/color-config';
import {faCheck} from '@fortawesome/pro-regular-svg-icons/faCheck';
import {faLock} from '@fortawesome/pro-regular-svg-icons/faLock';
import {BookmarkComposition} from '../../../vue-composition/bookmark/types';
import {ColumnArea} from '../../../core/column-layout/types';
import {HMSToString, secToHMS} from '../../../../utils/duration';
import {ImgixImageWidth} from '../../../core/imgix-image/types';

export default defineComponent({
  components: {
    ColumnGrid,
    ContentArea,
    Variants,
    Flow,
    Cluster,
    CardHeroImage,
    CardHeroShimmer,
    CardHeroText,
    CardProgressBar,
    CardBodyTitle,
    CardBodyTutorList,
    CardBodyCompleted,
    CardBodyLevelsAndSubjects,
    CardBodyDuration,
    CardBodySectionName,
    CardBodyComingSoon,
    CardBodyBookmark,
    CardBodyPostDate,
    CardSummary
  },
  props: {
    guest: {type: String, required: true},
    contentEntry: {type: Object as PropType<ContentEntry>, default: undefined},
    config: {
      type: Object as PropType<Readonly<DisplayConfig> | undefined>,
      default: undefined
    },
    duration: {type: Number, default: undefined}, // Seconds
    completed: {type: Boolean, default: undefined},
    progress: {type: Number, default: undefined}, // [0,100]
    unlocked: {type: Boolean, default: undefined},
    displayPostDate: {type: Boolean, default: false},
    spotColor: {type: Object as PropType<Readonly<ColorConfig> | undefined>, default: undefined},
    bookmark: {type: Object as PropType<Readonly<BookmarkComposition>>, default: undefined},
    cardImageWidth: {type: Object as PropType<Readonly<ImgixImageWidth>>, required: true},
    imgixParams: {type: Object, default: undefined}
  },
  setup(props) {
    const deviceType = useDeviceType();

    const completedStatus = computed(() => {
      if (props.completed !== undefined) {
        return props.completed;
      }
      if (props.progress !== undefined) {
        return props.progress === 100;
      }
      return undefined;
    });

    const greyscale = computed(() => {
      return completedStatus.value;
    });

    const responsiveImageConfig = computed(() => {
      if (props.contentEntry === undefined) {
        return undefined;
      }
      return getImageConfig(
        props.contentEntry.imageCollection,
        ['cover'],
        props.contentEntry.title,
        props.cardImageWidth,
        props.imgixParams
      );
    });

    const durationText = computed(() => {
      if (props.duration !== undefined) {
        return `${HMSToString(secToHMS(props.duration))}`;
      }
      return undefined;
    });

    const cardArea = deviceTypeSwitch<ColumnArea>({
      narrow: {start: 1, span: -1},
      sm: {start: 1, span: -1},
      md: {start: 1, span: 5},
      lg: {start: 1, span: 4},
      xl: {start: 1, span: 3},
      '2xl': {start: 1, span: 3}
    });
    const bodyArea = deviceTypeSwitch<ColumnArea>({
      narrow: {start: 1, span: -1},
      sm: {start: 1, span: -1},
      md: {start: 6, span: -1},
      lg: {start: 5, span: -1},
      xl: {start: 4, span: -1},
      '2xl': {start: 4, span: -1}
    });

    const sizes = {
      narrow: 300,
      sm: 350,
      md: 300,
      lg: 350,
      xl: 300,
      '2xl': 350
    };

    const cardWidth = computed(() => {
      return deviceType.largerThan('narrow') ? 'w-[90%]' : 'w-full';
    });

    const tutors = computed(() => {
      if (props.contentEntry === undefined) {
        return;
      }
      const tutors = tutorList(props.contentEntry);
      return tutors;
    });

    const url = computed(() => {
      if (props.contentEntry === undefined) {
        return;
      }
      return props.contentEntry.url;
    });

    const displayConfig = computed(() => {
      return props.config !== undefined ? props.config : DEFAULT_DISPLAY_CONFIG_INLINE_CARD;
    });

    const ribbonIcon = computed(() => {
      if (props.guest) {
        return undefined;
      }
      if (completedStatus.value !== undefined && completedStatus.value === true) {
        return faCheck;
      }
      if (props.unlocked !== undefined && !props.unlocked) {
        return faLock;
      }
      return undefined;
    });

    const summary = computed(() => {
      if (
        props.contentEntry === undefined ||
        props.contentEntry.summary === null ||
        props.contentEntry.summary === undefined
      ) {
        return undefined;
      }
      return props.contentEntry.summary;
    });
    const preview = computed(() => {
      if (props.contentEntry === undefined) {
        return true;
      }
      return props.contentEntry.preview;
    });

    return {
      preview,
      deviceType,
      ribbonIcon,
      greyscale,
      responsiveImageConfig,
      url,
      tutors,
      summary,
      completedStatus,
      displayConfig,
      bodyArea,
      cardArea,
      cardWidth,
      durationText,
      sizes
    };
  }
});
</script>
